import React, { useState, useEffect } from "react";
import { Table, Input, Row, Col, Card, Tabs, Button, Space } from "antd";
import Container from "@mui/material/Container";
import { SearchOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

function Admin() {
  const [rsvpData, setRsvpData] = useState([]);
  const [percentageCompletion, setPercentageCompletion] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [eventCounts, setEventCounts] = useState({});
  const [eventYesCounts, setEventYesCounts] = useState({});
  const [eventAttendingCounts, setEventAttendingCounts] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://35.174.7.139/alldata", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setRsvpData(data);
          const totalRSVPs = data.length;
          const completedRSVPs = data.filter(
            (item) => item.rsvpStatus === "submitted"
          ).length;
          const percentage = (completedRSVPs / totalRSVPs) * 100;
          setPercentageCompletion(percentage.toFixed(2));

          const counts = {};
          const yesCounts = {};
          const attendingCounts = {};
          data.forEach((item) => {
            for (const eventKey in item) {
              if (
                item[eventKey] === '{"IsInvited": true, "IsAttending": null}' ||
                item[eventKey] === '{"IsInvited": true, "IsAttending": true}' ||
                item[eventKey] === '{"IsInvited": true, "IsAttending": false}'
              ) {
                counts[eventKey] = (counts[eventKey] || 0) + 1;
              }
              if (
                item[eventKey] === '{"IsInvited": true, "IsAttending": true}'
              ) {
                yesCounts[eventKey] = (yesCounts[eventKey] || 0) + 1;
                if (
                  item[eventKey] === '{"IsInvited": true, "IsAttending": true}'
                ) {
                  attendingCounts[eventKey] =
                    (attendingCounts[eventKey] || 0) + 1;
                }
              }
            }
          });
          setEventCounts(counts);
          setEventYesCounts(yesCounts);
          setEventAttendingCounts(attendingCounts);
        } else {
          console.error("Error fetching data:", response.status);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);
  };

  const events = [
    "PritMehndi",
    "MuktiMehndi",
    "PritVidhi",
    "MuktiVidhi",
    "Haldi",
    "SangeetGarba",
    "Wedding",
  ];

  const renderTabPanes = () => {
    const allEventsTab = (
      <TabPane tab="All Events" key="all">
        <Table dataSource={filteredData} columns={generateColumns(null)} />
      </TabPane>
    );

    const eventTabs = events.map((event) => (
      <TabPane tab={event} key={event}>
        <Table
          dataSource={filteredData.filter((item) =>
            item[event].includes('"IsInvited": true')
          )}
          columns={generateColumns(event)}
        />
        {selectedEvent === event && renderEventCards(event)}
      </TabPane>
    ));

    return [allEventsTab, ...eventTabs];
  };

  const renderEventCards = (event) => {
    if (event === null) {
      return null; // No cards for "All Events" tab
    }

    const totalInvited = eventCounts[event] || 0;
    const totalAttending = eventAttendingCounts[event] || 0;
    const totalNotAttending = filteredData.filter(
      (item) => item[event] === '{"IsInvited": true, "IsAttending": false}'
    ).length;
    const totalLeftToRSVP = totalInvited - totalAttending - totalNotAttending;

    const cardTitleStyle = {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "14px", // Adjust title font size as needed
    };

    const cardDataStyle = {
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "18px", // Adjust data font size as needed
    };

    return (
      <Row gutter={16} justify="center">
        <Col span={6}>
          <Card title={`Invited`} style={cardTitleStyle}>
            <div style={cardDataStyle}>{totalInvited}</div>
          </Card>
        </Col>
        <Col span={6}>
          <Card title={`Attending`} style={cardTitleStyle}>
            <div style={cardDataStyle}>{totalAttending}</div>
          </Card>
        </Col>
        <Col span={6}>
          <Card title={`Not Attending`} style={cardTitleStyle}>
            <div style={cardDataStyle}>{totalNotAttending}</div>
          </Card>
        </Col>
        <Col span={6}>
          <Card title={`RSVP Pending`} style={cardTitleStyle}>
            <div style={cardDataStyle}>{totalLeftToRSVP}</div>
          </Card>
        </Col>
      </Row>
    );
  };

  const filteredData = rsvpData.filter((item) => {
    const fullName = `${item.FirstName} ${item.LastName}`;
    const groupIDString = String(item.GroupID); // Convert GroupID to string
    return (
      fullName.toLowerCase().includes(searchQuery) ||
      groupIDString.includes(searchQuery.toLowerCase())
    );
  });

  const generateColumns = (event) => {
    const commonColumns = [
      {
        title: "Group ID",
        dataIndex: "GroupID",
        key: "GroupID",
      },
      {
        title: "RSVP Status",
        dataIndex: "rsvpStatus",
        key: "rsvpStatus",
        filters: [
          { text: "Submitted", value: "Submitted" },
          { text: "Not Submitted", value: "No" },
        ],
        onFilter: (value, record) => record.rsvpStatus.indexOf(value) === 0,
        render: (text) => {
          const cellStyle = {
            color: text === "Submitted" ? "green" : "red",
          };
          return <span style={cellStyle}>{text}</span>;
        },
      },
      {
        title: "First Name",
        dataIndex: "FirstName",
        key: "FirstName",
      },
      {
        title: "Last Name",
        dataIndex: "LastName",
        key: "LastName",
      },
    ];

    if (event) {
      return [
        ...commonColumns,
        {
          title: event,
          dataIndex: event,
          key: event,
          filters: [
            {
              text: "Attending",
              value: '{"IsInvited": true, "IsAttending": true}',
            },
            {
              text: "Not Attending",
              value: '{"IsInvited": true, "IsAttending": false}',
            },
          ],
          onFilter: (value, record) => {
            const filterObject = JSON.parse(value);
            const recordObject = JSON.parse(record[event]);

            return (
              recordObject.IsInvited === filterObject.IsInvited &&
              recordObject.IsAttending === filterObject.IsAttending
            );
          },
          render: (text, record) => {
            const statusMap = {
              '{"IsInvited": false, "IsAttending": null}': "Not Invited",
              '{"IsInvited": true, "IsAttending": null}': "Invited",
              '{"IsInvited": true, "IsAttending": true}': "Attending",
              '{"IsInvited": true, "IsAttending": false}': "Not Attending",
            };
            let color;
            if (text === '{"IsInvited": true, "IsAttending": true}') {
              color = "green";
            } else if (text === '{"IsInvited": true, "IsAttending": false}') {
              color = "red";
            } else {
              color = "black";
            }
            const cellStyle = {
              color: color,
            };
            return <span style={cellStyle}>{statusMap[text]}</span>;
          },
        },
      ];
    } else {
      return commonColumns.concat(
        events.map((e) => ({
          title: e,
          dataIndex: e,
          key: e,
          render: (text) => {
            const statusMap = {
              '{"IsInvited": false, "IsAttending": null}': "Not Invited",
              '{"IsInvited": true, "IsAttending": null}': "Invited",
              '{"IsInvited": true, "IsAttending": true}': "Attending",
              '{"IsInvited": true, "IsAttending": false}': "Not Attending",
            };
            let color;
            if (text === '{"IsInvited": true, "IsAttending": true}') {
              color = "green";
            } else if (text === '{"IsInvited": true, "IsAttending": false}') {
              color = "red";
            } else {
              color = "black";
            }
            const cellStyle = {
              color: color
            };
            return <span style={cellStyle}>{statusMap[text]}</span>;
          },
        }))
      );
    }
  };

  return (
    <Container>
      <Input
        placeholder="Search by name or Group ID"
        onChange={handleSearch}
        value={searchQuery}
        style={{
          width: "300px",
          marginRight: "16px",
          marginBottom: "10px",
          marginTop: "20px",
        }}
      />
      <Tabs
        defaultActiveKey={events[0]}
        tabPosition="left"
        onChange={(key) => setSelectedEvent(key)}
      >
        {renderTabPanes()}
      </Tabs>
    </Container>
  );
}

export default Admin;
