import React, { useState } from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "./Form.css";
import TextField from "@mui/material/TextField";
import Button from "antd/es/button/button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import EventsComponent from "./EventsComponent";
import Input from "antd/es/input/Input";
import { List, message } from "antd";

function Form() {
  const [fullName, setFullName] = useState("");
  const [fetchedData, setFetchedData] = useState([]);
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [associatedNames, setAssociatedNames] = useState([]);
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [isBackButtonVisible, setIsBackButtonVisible] = useState(false);

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const [firstName, lastName] = fullName.split(" ");
    if (!firstName || !lastName) {
      message.error({
        content: "Please provide both first name and last name.",
        style: {
            marginTop: '67vh' // Position the message halfway down the viewport vertically
        } 
      }
      );
      return; // Exit the function early
    }
    try {
      //const response = await fetch("http://localhost:8000/names", {
      const response = await fetch("https://35.174.7.139/names", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ firstName, lastName }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.names.length === 0) {
        message.error({
          content: "Name not found. Please try again. If you are having trouble, please reach out to the couple.",
          style: {
              marginTop: '67vh' // Position the message halfway down the viewport vertically
          }
          
        }
          
        );
        return; // Exit the function early
      }

      setFetchedData(data.names);
      setHasFetchedData(true);
      setSelectedGroupId(null);
      setAssociatedNames([]);
      setIsFormVisible(false); // Hide the form
      setIsBackButtonVisible(true); // Show the back button
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Error fetching data. Please try again.");
      setHasFetchedData(true);
      setSelectedGroupId(null);
      setAssociatedNames([]);
    }
  };

  const handleThisIsMeClick = async (event) => {
    const groupId = event.currentTarget.dataset.groupid;
    //should open a form of both the people and the events based on the ones in the backend data

    console.log(`This is me button clicked for GroupID: ${groupId}`);

    try {
      //const response = await fetch("http://localhost:8000/groupNames", {
      const response = await fetch("https://35.174.7.139/groupNames", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ groupId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(data)
      console.log(
       // `Names associated with GroupID ${groupId}:`,
       // data.namesWithTags
       data
      );
      // Extract first and last names from the response
      const names = data.namesWithTags.map(
        (item) => `${item.FirstName} ${item.LastName}`
      );
      // Update state with associated names
      setAssociatedNames(data.namesWithTags);
      // Set the selected group ID
      setSelectedGroupId(groupId);
      // Reset the fetchedData state
      setFetchedData([]);
      // Set the hasFetchedData state to false
      setHasFetchedData(false);
      // Hide the form
      setIsFormVisible(false);
      // Show the back button
      setIsBackButtonVisible(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Error fetching data. Please try again.");
    }
  };

  const handleBackButtonClick = () => {
    // Clear fetched data and reset state
    setFetchedData([]);
    setHasFetchedData(false);
    setSelectedGroupId(null);
    setAssociatedNames([]);
    // Show the form
    setIsFormVisible(true);
    // Hide the back button
    setIsBackButtonVisible(false);
  };

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      {isFormVisible && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Align items horizontally
            justifyContent: "center", // Align items vertically
            borderRadius: 4,
            backgroundColor: "#e8e8e8",
            padding: 3,
            marginBottom: 2,
            height: "50vh", // Adjust the height as needed
            overflowY: "auto",
          }}
        >
          <Box sx={{ width: "80%", marginBottom: 1 }}>
            <Typography
              variant="h2"
              textAlign={"center"}
              fontFamily={"Goudy"}
              component="div"
              gutterBottom
            >
              <img
                src="/assets/images/monogram.png"
                alt="monogram"
                style={{ width: "15%" }}
              />
            </Typography>
            <Typography
              variant="h6"
              textAlign={"center"}
              fontFamily={"Goudy"}
              component="div"
              gutterBottom
            >
              Please enter the first and last name of one member of your party
              below. If you're responding for you and a guest (or your family),
              you'll be able to RSVP for your entire group on the next page.
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center", // This centers the content horizontally
              }}
            >
              <Input
                size="large"
                style={{ marginBottom: 10, width: 300, fontFamily: "Goudy" }}
                placeholder="Full Name"
                value={fullName}
                onChange={handleFullNameChange}
                required
              />
              <Button
                size="large"
                onClick={handleSubmit}
                style={{
                  marginTop: 12,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Goudy",
                }}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      )}

      {hasFetchedData && (
        <Box
          sx={{
            borderRadius: 4,

            padding: 3,
            width: "100%",
            marginBottom: 2,
            maxHeight: "100vh", // Adjust the height as needed
          }}
        >
          {fetchedData.length > 0 ? (
            <div>
              <div>
                <Typography
                  sx={{ fontFamily: "Goudy" }}
                  variant="h6"
                  gutterBottom
                >
                  Select your info below or try searching again.
                </Typography>
                {fetchedData.map((group, index) => (
                  <div>
                    <Box
                      key={index}
                      sx={{
                        borderRadius: 4,
                        padding: 2,
                        marginBottom: 2,
                        display: "flex",
                        justifyContent: "space-between", // Adjusts items to be on opposite ends
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <List>
                        {group.data.map((item, itemIndex) => (
                          <li
                            key={itemIndex}
                            style={{
                              fontWeight: "bold",
                              fontFamily: "Goudy",
                              fontSize: "16px",
                            }}
                          >
                            {item.FirstName} {item.LastName}
                          </li>
                        ))}
                      </List>

                      <Button
                        variant="outlined"
                        color="primary"
                        data-groupid={group.groupId}
                        onClick={handleThisIsMeClick}
                        style={{
                          marginTop: 12,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          fontFamily: "Goudy",
                        }}
                      >
                        Select
                      </Button>
                    </Box>
                    <Divider
                      sx={{
                        borderBottom: "2px solid grey",
                        marginTop: 2,
                        color: "#000000",
                      }}
                    />
                  </div>
                ))}
              </div>
              <div
                style={{
                  marginTop: 12,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Goudy",
                }}
              >
                {isBackButtonVisible && (
                  <div
                    style={{
                      marginTop: 12,
                      marginBottom: 12,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "Goudy",
                    }}
                  >
                    <Typography sx={{ fontFamily: "Goudy" }}>
                      If none of these are you, please reach out to the couple
                      to see exactly how they entered your details.
                    </Typography>

                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      onClick={handleBackButtonClick}
                      style={{
                        marginTop: 12,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: "Goudy",
                      }}
                    >
                      Back to Form
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No data found
            </Typography>
          )}
        </Box>
      )}

      {selectedGroupId && (
        <EventsComponent family={associatedNames} groupId={selectedGroupId} />
      )}
    </Container>
  );
}

export default Form;
