import React, { useState, useEffect } from "react";
import {
  Typography,
  FormControlLabel,
  FormControl,
  Box,
  Card,
  Divider,
} from "@mui/material";
import Button from "antd/es/button/button";
import Radio from "@mui/material/Radio";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import RadioGroup from "@mui/material/RadioGroup";

//Test Comment
const EventsComponent = ({ family, groupId }) => {
  const allEvents = [
    "PritMehndi",
    "MuktiMehndi",
    "PritVidhi",
    "MuktiVidhi",
    "Haldi",
    "SangeetGarba",
    "Wedding",
  ];
  const eventLocation = {
    PritMehndi: "Thursday, April 25, 2024",
    MuktiMehndi: "Thursday, April 25, 2024",
    PritVidhi: "Friday, April 26, 2024",
    MuktiVidhi: "Friday, April 26, 2024",
    Haldi: "Saturday, April 27, 2024",
    SangeetGarba: "Saturday, April 27, 2024",
    Wedding: "Sunday, April 28, 2024",
  };
  const eventAddresses = {
    PritMehndi: "4:30 PM",
    MuktiMehndi: "6:30 PM",
    PritVidhi: "3:00 PM",
    MuktiVidhi: "9:00 AM",
    Haldi: "10:00 AM",
    SangeetGarba: "6:30 PM",
    Wedding: "Baarat: 9:30 AM | Wedding: 10:00 AM",
  };
  const [events, setEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState(0);
  const [rsvpResponses, setRsvpResponses] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const formatEventNameForDisplay = (eventName) => {
    switch (eventName) {
      case "PritMehndi":
        return "Prit's Mehndi";
      case "MuktiMehndi":
        return "Mukti's Mehndi";
      case "PritVidhi":
        return "Prit's Vidhi";
      case "MuktiVidhi":
        return "Mukti's Vidhi";
      case "Haldi":
        return "Haldi";
      case "SangeetGarba":
        return "Sangeet and Garba";
      case "Wedding":
        return "Wedding";
      default:
        return eventName; // Default case for any other event
    }
  };

  useEffect(() => {
        // Check if any family member's rsvpStatus is "Submitted"
        const isAnyMemberSubmitted = family.some(
          (member) => member.rsvpStatus === "Submitted"
        );
        // Set isSubmitted to true if any member's rsvpStatus is "Submitted"
        setIsSubmitted(isAnyMemberSubmitted);
    const filteredEvents = allEvents.filter((event) =>
      family.some((member) => {
        let eventData = JSON.parse(member[event]);
        return eventData && eventData.IsInvited;
      })
    );
    setEvents(filteredEvents);
  }, [family]);

  const handleRsvpChange = (event, memberFirstName, eventName) => {
    setRsvpResponses({
      ...rsvpResponses,
      [eventName]: {
        ...rsvpResponses[eventName],
        [memberFirstName]: event.target.value,
      },
    });
  };

  const handleNext = () => {
    if (currentEvent < events.length - 1) {
      setCurrentEvent(currentEvent + 1);
    }
  };

  const handleBack = () => {
    if (currentEvent > 0) {
      setCurrentEvent(currentEvent - 1);
    }
  };

  const handleSubmit = async () => {
    let submissionData = family.map((member) => {
      return {
        GroupID: groupId,
        FirstName: member.FirstName,
        LastName: member.LastName,
        PritMehndi: rsvpResponses["PritMehndi"]?.[member.FirstName],
        MuktiMehndi: rsvpResponses["MuktiMehndi"]?.[member.FirstName],
        PritVidhi: rsvpResponses["PritVidhi"]?.[member.FirstName],
        MuktiVidhi: rsvpResponses["MuktiVidhi"]?.[member.FirstName],
        Haldi: rsvpResponses["Haldi"]?.[member.FirstName],
        SangeetGarba: rsvpResponses["SangeetGarba"]?.[member.FirstName],
        Wedding: rsvpResponses["Wedding"]?.[member.FirstName],
      };
    });

    try {
      //Change IP Address Here Depending on Where Hosted
      const response = await fetch("https://35.174.7.139/submitRsvp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(submissionData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log("RSVPs submitted successfully:", result);
      setIsSubmitted(true); // Update state after successful submission
    } catch (error) {
      console.error("Error submitting RSVP data:", error);
      // Optionally handle the error state
    }
  };

  const isSubmittedFalse = () => {
    setIsSubmitted(false);
  };

  if (isSubmitted) {
    return (
      <Box
        sx={{
          borderRadius: 4,
          padding: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "Goudy",
          marginTop: "100px"
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontFamily: "Goudy",
            textAlign: "center",
          }}
        >
          Thank you for your RSVP
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={isSubmittedFalse}
          style={{
            marginTop: 12,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "Goudy",
          }}
        >
          Change Your RSVP
        </Button>

        {allEvents.map((event, eventIndex) => {
          const isInvitedToEvent = family.some(
            (member) => JSON.parse(member[event]).IsInvited
          );

          if (!isInvitedToEvent) {
            return null;
          }

          return (
            <Box key={eventIndex} sx={{ width: "100%", mt: 2, mb: 2 }}>
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Goudy",
                  fontWeight: "bold",
                  textAlign: "center",
                  mb: 2,
                }}
              >
                {formatEventNameForDisplay(event)}
              </Typography>
              {family.map((member, memberIndex) => {
                const eventData = JSON.parse(member[event]);
                if (!eventData.IsInvited) {
                  return null;
                }

                let response = rsvpResponses[event]?.[member.FirstName];
                console.log(response)
                if (response === undefined) {
        
                if (eventData.IsAttending === true) {
                  response = "Attending";
                } else if (eventData.IsAttending === false) {
                  response = "Not Attending";
                } else {
                  response = ""; // No response
                }
              }
                  

                
                let responseIcon;
                let responseText;

                if (response === "Attending") {
                  responseIcon = <CheckIcon sx={{ color: "green", mr: 1 }} />;
                  responseText = "Attending";
                } else if (response === "Not Attending") {
                  responseIcon = <CloseIcon sx={{ color: "red", mr: 1 }} />;
                  responseText = "Not Attending";
                } else {
                  responseText = "No response";
                }

                return (
                  <Box
                    key={memberIndex}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontFamily: "Goudy",
                    }}
                  >
                    <Typography sx={{ fontFamily: "Goudy" }}>
                      {member.FirstName} {member.LastName}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {responseIcon}
                      <Typography sx={{ fontFamily: "Goudy" }}>
                        {responseText}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
              {eventIndex < allEvents.length - 1 && (
                <Divider sx={{ width: "100%", mt: 2 }} />
              )}
            </Box>
          );
        })}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%", // Set width to 100% for smaller screens
        fontFamily: "Goudy",
      }}
    >
      {isSubmitted ? (
        <Typography sx={{ fontFamily: "Goudy" }} variant="h4" gutterBottom>
          Thank you for your submission. We can't wait to see you.
        </Typography>
      ) : (
        events.length > 0 && (
          <>
            <Typography variant="h3" sx={{ mb: 2, fontFamily: "Goudy" }}>
              {formatEventNameForDisplay(events[currentEvent])}
            </Typography>
            <Typography variant="h6" sx={{ fontFamily: "Goudy" }}>
              {eventLocation[events[currentEvent]]}
            </Typography>
            <Typography variant="h6" sx={{ mb: 2, fontFamily: "Goudy" }}>
              {eventAddresses[events[currentEvent]]}
            </Typography>

            <Box sx={{ width: "100%" }}>
              {family.map((member, index) => {
                let eventData = JSON.parse(member[events[currentEvent]]);
                if (eventData && eventData.IsInvited) {
                  const memberEventResponse =
                    rsvpResponses[events[currentEvent]]?.[member.FirstName] ||
                    "";
                  return (
                    <>
                      <Box
                        key={index}
                        sx={{
                          borderRadius: 4,
                          padding: 2,
                          marginBottom: 2,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "row",
                          fontFamily: "Goudy",
                        }}
                      >
                        <Typography
                          sx={{
                            mr: 2,
                            fontFamily: "Goudy",
                            fontWeight: "bold",
                            fontSize: "18px",
                          }}
                        >
                          {member.FirstName} {member.LastName}
                        </Typography>
                        <FormControl
                          component="fieldset"
                          style={{ fontFamily: "Goudy" }}
                        >
                          <RadioGroup
                            style={{ fontFamily: "Goudy" }}
                            row
                            name={`rsvp-${events[currentEvent]}-${member.FirstName}`}
                            value={memberEventResponse}
                            onChange={(e) =>
                              handleRsvpChange(
                                e,
                                member.FirstName,
                                events[currentEvent]
                              )
                            }
                          >
                            <FormControlLabel
                              sx={{ fontFamily: "Goudy" }}
                              value="Attending"
                              control={<Radio />}
                              label="Attending"
                              style={{ fontFamily: "Goudy" }}
                            />
                            <FormControlLabel
                              sx={{ fontFamily: "Goudy" }}
                              value="Not Attending"
                              control={<Radio />}
                              label="Not Attending"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Divider
                        sx={{
                          borderBottom: "2px solid grey",
                          marginBottom: 2,
                          marginTop: 2,
                          color: "#000000",
                        }}
                      />
                    </>
                  );
                }
                return null;
              })}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {currentEvent > 0 && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleBack}
                  style={{
                    marginTop: 12,
                    marginRight: 8,
                    fontFamily: "Goudy",
                  }}
                >
                  Previous Event
                </Button>
              )}
              {currentEvent < events.length - 1 && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleNext}
                  style={{
                    marginTop: 12,
                    marginLeft: 8,
                    fontFamily: "Goudy",
                  }}
                >
                  Next Event
                </Button>
              )}
              {currentEvent === events.length - 1 && (
                <Button
                  onClick={handleSubmit}
                  style={{
                    marginTop: 12,
                    backgroundColor: "#2c2c2c",
                    color: "#ffffff",
                    marginLeft: 8,
                    fontFamily: "Goudy",
                  }}
                >
                  Submit RSVPs
                </Button>
              )}
            </Box>
          </>
        )
      )}
    </Box>
  );
};

export default EventsComponent;